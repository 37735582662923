<template>
  <main class="supplier-payment supplier-payment-confirmation-police page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PAYMENT_POLICE_REPORTS") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/police.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-end pt10">
          <a class="primary-btn bg-2 cancel-btn pointer cancel-btn">
            {{ $t("SUPPLIER_PAYMENT.CANCELATION") }}
          </a>
        </div>
      </div>
    </section>
    <section class="main-content">
      <p class="weight-700 m-b-15">{{ $t("SUPPLIER_PAYMENT.POLICE_DATABASE_TXT") }}</p>
      <div class="police-table bg-white">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="policeData"
          item-key="debt"
          show-select
          class="cus-table"
        >
          <template v-slot:[`item.paymentSum`]="{ item }">
            <span class="color-red3">
              {{ item.paymentSum }}
            </span>
          </template>
        </v-data-table>
      </div>
      <div class="police-form">
        <div class="inline-form_wrap">
          <p>{{ $t("SUPPLIER_PAYMENT.NUMBER_SELECTED_REPORT") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="inline-form_wrap">
          <p>{{ $t("SUPPLIER_PAYMENT.TOTAL_SUM_ACCOUNTS") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="inline-form_wrap">
          <p>{{ $t("CARMEL_TUNNELS.SERVICE_FEE") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="inline-form_wrap">
          <p class="color-red3 weight-700">{{ $t("SUPPLIER_PAYMENT.TOTAL_PAYMENT") }}: </p>
          <input type="text" class="input">
        </div>
        <div class="form_wrap m-t-20">
          <p class="m-b-5 fs16">{{ $t("SUPPLIER_PAYMENT.MOBILE_PHONE_RECEPTION") }}</p>
          <input type="text" class="input">
        </div>
        <div class="d-flex m-t-10">
          <v-checkbox color="#0D3856" class="cus-checkbox"></v-checkbox>
          <label for="" class="fs16">
            <b class="color-red3">{{ $t("SUPPLIER_PAYMENT.POLICE_FOOTER_TXT1") }} </b>
            {{ $t("SUPPLIER_PAYMENT.POLICE_FOOTER_TXT2") }}
          </label>
        </div>
        <button class="primary-btn finish-btn m-t-20">
          {{ $t("CARMEL_TUNNELS.WHOLE") }}
        </button>
      </div>
      <div class="police-footer m-t-20">
        <p class="weight-700 fs30 color-blue1">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "SupplierPaymentConfirmation212",
  props: {
    msg: String,
  },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: this.$t("SUPPLIER_PAYMENT.PORTFOLIO_VOUCHER_TAX"),
          value: 'voucherTax',
          align: "center"
        },
        { 
          text: this.$t("SUPPLIER_PAYMENT.DEBT_FILE_FACTOR"), 
          value: 'debt', 
          align: "center"
        },
        { 
          text: this.$t("SUPPLIER_PAYMENT.DATE_OF_PAYMENT"), 
          value: 'date',
          align: "center" 
        },
        { 
          text: this.$t("SUPPLIER_PAYMENT.FOUNDATION"), 
          value: 'foundation',
          align: "center" 
        },
        { 
          text: this.$t("SUPPLIER_PAYMENT.ARREARS_AND_EXPENSES"), 
          value: 'arrears',
          align: "center" 
        },
        { 
          text: this.$t("CARMEL_TUNNELS.PAYMENT_SUM"), 
          value: 'paymentSum',
          align: "center" 
        }
      ],
      policeData: [
        {
          voucherTax: 'ד-5543345',
          debt: 90515640291,
          date: '23/06/2020',
          foundation: 250,
          arrears: 0,
          paymentSum: 250,
        },
        {
          voucherTax: 'ד-5543345',
          debt: 90456640291,
          date: '23/06/2020',
          foundation: 250,
          arrears: 0,
          paymentSum: 250,
        },
        {
          voucherTax: 'ד-5543345',
          debt: 90515645691,
          date: '23/06/2020',
          foundation: 250,
          arrears: 0,
          paymentSum: 250,
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.supplier-payment-confirmation-police {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
    .supplier-logo {
      max-width: 120px;
      min-width: unset;
    }
    .payment-product-name {
      font-size: 30px;
    }
    .cancel-btn {
      max-width: 150px;
    }
  }
  .main-content {
    margin: 0 auto;
    max-width: 1150px;
    margin-top: 30px;
    color: #002036;
    .police-table {
      padding: 10px 0;
    }
    .police-form {
      max-width: 500px;
      margin: 0 auto;
      margin-top: 30px;
      .inline-form_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        p {
          width: 50%;
          padding: 0 10px;
          font-size: 20px;
        }
        .input {
          width: 50%;
          padding: 0 10px;
          height: 35px;
        }
      }
      .cus-checkbox {
        margin-top: 7px;
        margin-left: 10px;
      }
    }
    .police-footer {
      text-align: center;
      .primary-btn {
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>