<template>
    <main class="supplier-payment-confirmation supplier-payment-confirmation-186 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span>{{ $t("SUPPLIER_PAYMENT.PURCHASE_POWER_CODE_TXT") }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img
                        class="supplier-logo"
                        src="/assets/img/logo/n-logo.png"
                    />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-confirmation-data-section">

            <div class="payment-confirmation-data-top-block">
                <span class="payment-confirmation-data-top-block-text">{{ $t("CARMEL_TUNNELS.VERIFICATION_BEFORE_LOADING") }}</span>
            </div>

            <div class="payment-confirmation-data">

                <div class="payment-confirmation-data-block">
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.CUSTOMERS_NAME") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ name }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("COMMON.ADDRESS") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ address }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("REPORT_SALES.CONTRACT_NUMBER") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ contractNumber }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.PURCHASE_AMOUNT") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ real_amount }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.TOTAL_NET_EXCLUDING_VAT") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ net_amount }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.KWH") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ ktwh }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.PAYMENT_DEBTS_TXT") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ amount_of_debts }}</div>
                    </div>
                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">{{ $t("SUPPLIER_PAYMENT.TOTAL_CHARGES_ADDITIONAL_CREDITS") }}:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ total_charges_or_additional_credits }}</div>
                    </div>
                </div>
            </div>

            <div class="payment-confirmation-data-bottom-block">

                <div class="payment-confirmation-data-bottom-block-text"><span class="red-text">{{ $t("CARMEL_TUNNELS.AFTER_VERIFY_TXT") }}</span></div>

                <div class="payment-confirmation-data-bottom-block-button">
                    <a class="link payment-button" @click="handleSupplierPaymentConfirmationSubmit">{{ $t("CARMEL_TUNNELS.CONFIRMATION_PURCHASE") }}</a>
                </div>

                <div class="payment-form-section-bottom-block">
                    <span class="payment-confirmation-data-bottom-block-text color-red2">{{ $t("SUPPLIER_PAYMENT.PAY_ATTENTION_TXT") }}</span>
                </div>
                <div class="payment-form-section-bottom-block m-t-20">
                    <span class="payment-confirmation-data-bottom-block-text">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</span>
                </div>
            </div>

        </section>

    </main>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import appConfig from '../../../appConfig'

export default {
    name: 'SupplierPaymentConfirmation186',
    props: {
        msg: String
    },
    data() {
        return {
            currentDate: new Date()
        }
    },
    mounted() {
    },
    computed: {
        ...mapState({
            current_payment_data: state => state.supplier_payment.current_payment_data,
            supplier_items: state => state.supplier.supplier_items,
            suppliers: state => state.supplier.suppliers,
            user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null
        }),
        supplierId() {
            return this.$route.params.supplier_id
        },
        supplierData() {
            let supplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.supplierId) {
                            supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let supplierDataFromSuppliersData = null;
            if (this.suppliers && this.supplierId in this.suppliers) {
                supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
            }

            let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
            return supplierData ? supplierData : null
        },
        paymentData() {
            let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

            return paymentData
        },
        contractNumber() {
            return this.paymentData && this.paymentData.hasOwnProperty('contractNumber') ? this.paymentData.contractNumber : ''
        },
        sumToPay() {
            return this.paymentData && this.paymentData.hasOwnProperty('sumToPay') ? this.paymentData.sumToPay : ''
        },
        finalCommission() {
            return this.paymentData && this.paymentData.hasOwnProperty('finalCommission') ? this.paymentData.finalCommission : ''
        },
        price() {
            return this.paymentData && this.paymentData.hasOwnProperty('price') ? this.paymentData.price : ''
        },
        address() {
            return this.paymentData && this.paymentData.hasOwnProperty('address') ? this.paymentData.address : ''
        },
        invoice() {
            return this.paymentData && this.paymentData.hasOwnProperty('invoice') ? this.paymentData.invoice : ''
        },
        name() {
            return this.paymentData && this.paymentData.hasOwnProperty('name') ? this.paymentData.name : ''
        },
        last_sale() {
            return this.paymentData && this.paymentData.hasOwnProperty('last_sale') ? this.paymentData.last_sale : ''
        },
        counter_number() {
            return this.paymentData && this.paymentData.hasOwnProperty('counter_number') ? this.paymentData.counter_number : ''
        },
        real_amount() {
            return this.paymentData && this.paymentData.hasOwnProperty('real_amount') ? this.paymentData.real_amount : ''
        },
        net_amount() {
            return this.paymentData && this.paymentData.hasOwnProperty('net_amount') ? this.paymentData.net_amount : ''
        },
        ktwh() {
            return this.paymentData && this.paymentData.hasOwnProperty('ktwh') ? this.paymentData.ktwh : ''
        },
        amount_of_debts() {
            return this.paymentData && this.paymentData.hasOwnProperty('amount_of_debts') ? this.paymentData.amount_of_debts : ''
        },
        total_charges_or_additional_credits() {
            return this.paymentData && this.paymentData.hasOwnProperty('total_charges_or_additional_credits') ? this.paymentData.total_charges_or_additional_credits : ''
        },
        itemId() {
            return this.paymentData && this.paymentData.hasOwnProperty('itemId') ? this.paymentData.itemId : ''
        },
        phoneNumber() {
            return this.paymentData && this.paymentData.hasOwnProperty('phoneNumber') ? this.paymentData.phoneNumber : ''
        },
        businessName() {
            return this.user_details && this.user_details.businessName ? this.user_details.businessName : ''
        },
        currentDateString() {
            let currentDateString = this.currentDate.getDate().toString().padStart(2, '0') + '/' + (this.currentDate.getMonth()+1).toString().padStart(2, '0') + '/' + this.currentDate.getFullYear()

            return currentDateString
        },
        currentTimeString() {
            let currentTimeString = this.currentDate.getHours().toString().padStart(2, '0') + ":" + this.currentDate.getMinutes().toString().padStart(2, '0') + ":" + this.currentDate.getSeconds().toString().padStart(2, '0')

            return currentTimeString
        }
    },
    created() {
    },
    methods: {
        ...mapActions('supplier_payment', {
            supplierPayment: 'supplierPayment'
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        handleSupplierPaymentConfirmationSubmit() {
            const supplierId = this.supplierId
            const itemId = this.itemId
            const contractNumber = this.contractNumber
            const price = this.price
            const phoneNumber = this.phoneNumber

            if (supplierId != '' && itemId != '' && contractNumber != ''
                && phoneNumber != '' && price != '') {
                this.supplierPayment(
                    {
                        supplier_id: supplierId,
                        payment_data: {
                            itemId: itemId,
                            contractNumber: contractNumber,
                            phoneNumber: phoneNumber,
                            price: price
                        },
                    })
                    .then(
                        result_data => {
                        },
                        error => {
                        }
                    );
            }
        }
    },
    updated: function () {
    },
}
</script>

<style lang="scss" scoped>
    .supplier-payment-confirmation-186 {
        .supplier-logo {
            max-width: 100px;
        }
        .payment-product-name,
        .payment-confirmation-data-top-block-text,
        .payment-confirmation-data-bottom-block-text {
            font-size: 30px;
        }
        .payment-confirmation-data-block-row, 
        .payment-success-data-block-2-columns .payment-success-data-block-row {
            font-size: 20px;
        }
        .payment-confirmation-data {
            max-width: 600px;
            padding: 0;
        }
        .payment-confirmation-data-block-row-label {
            min-width: 430px;
        }
        .payment-confirmation-data-block-row-data {
            width: unset;
        }
    }
    .ltr-type {
        .payment-form {
           text-align: left; 
        }
        .input-tooltip-container {
            left: unset;
            right: 0;
        }
        .payment-form-with-tooltips .payment_field_wrapper .input {
            margin-left: 0;
            margin-right: 12px;
        }
    }
    @media screen and (max-width: 600px) {
        .payment-confirmation-data-block-row-label, 
        .payment-confirmation-data-block-row-data, 
        .payment-success-data-block-2-columns .payment-success-data-block-row-label, 
        .payment-success-data-block-2-columns .payment-success-data-block-row-data {
            display: block;
            width: 100%;
        }
    }
</style>