<template>
  <main class="supplier-payment supplier-payment-213 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PAYMENT_6CROSSES_NORTH") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/6North.png"
          />
        </div>

        <div class="payment-caption-wrapper">&nbsp;</div>
      </div>
    </section>

    <section class="payment-form-section">
      <div class="payment-confirmation-data">

        <div class="payment-confirmation-data-block">
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.ID_NUMBER") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              045652456
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.INVOICES") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              564656
            </div>
          </div>
          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("SUPPLIER_PAYMENT.AMOUNT_OF_INVOICES") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              405.95ש"ח
            </div>
          </div>

          <div class="payment-confirmation-data-block-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.SERVICE_FEE") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              7 ש"ח לחשבונית
            </div>
          </div>

          <div class="payment-confirmation-data-block-row red-row">
            <div class="payment-confirmation-data-block-row-label">
              {{ $t("CARMEL_TUNNELS.PAYMENT_SUM") }}:
            </div>
            <div class="payment-confirmation-data-block-row-data">
              419.95₪
            </div>
          </div>
          <div class="confirm-action m-t-30">
            <button class="primary-btn finish-btn">
              {{ $t("SUPPLIER_PAYMENT.FINISHED_AND_CLOSED") }}
            </button>
            <p class="text-center weight-700 fs20 m-t-5">
              {{ $t("SUPPLIER_PAYMENT.BEZEQ_TXT3") }}
            </p>
          </div>
        </div>
      </div>

      <p class="color-red2 fs30 text-center weight-700 m-t-30">
        {{ $t("CARMEL_TUNNELS.AFTER_VERIFY_TXT") }}
      </p>
      <p class="color-blue1 fs30 text-center weight-700 m-t-10">
        {{ $t("CARMEL_TUNNELS.RESULT_TXT") }}
      </p>
    </section>
  </main>
</template>

<script>
export default {
  name: "SupplierPaymentResult213"
};
</script>
<style lang="scss" scoped>
  .supplier-payment-213 {
    .payment-caption-container {
      align-items: center;
      .payment-caption-wrapper {
        margin-top: 0;
      }
      .supplier-logo {
        max-width: 100px;
        min-width: unset;
      }
      .payment-product-name {
        font-size: 30px;
      }
    }
    .payment-confirmation-data-block-row,
    .payment-success-data-block-2-columns .payment-success-data-block-row {
      font-size: 20px;
      padding: 0 30px;
    }
  }
</style>