<template>
    <main class="supplier-payment-confirmation supplier-payment-confirmation-187 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span v-if="supplierData && supplierData.name">{{ supplierData.name }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img class="supplier-logo" v-if="supplierData && supplierData.image" :src="supplierData.image" />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-confirmation-data-section">

            <div class="payment-confirmation-data-top-block">
                <span class="payment-confirmation-data-top-block-text">אימות פרטי לקוח לפני ביצוע הטעינה</span>
            </div>

            <div class="payment-confirmation-data">

                <div class="payment-confirmation-data-block">

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-title">פרטי מקום העסקה</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">שם העסק:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ businessName }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">תאריך:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ currentDateString }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">שעה:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ currentTimeString }}</div>
                    </div>

                </div>

                <div class="payment-confirmation-data-block">

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-title">פרטי החשבונית</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">מספר ת.ז/ח.פ:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ customerId }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">מספר מסלקה:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ billNumber }}</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">סכום חשבונית:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ sumToPay }} ש"ח</div>
                    </div>

                    <div class="payment-confirmation-data-block-row">
                        <div class="payment-confirmation-data-block-row-label">עמלת שרות:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ finalCommission }} ש"ח</div>
                    </div>

                    <div class="payment-confirmation-data-block-row red-row">
                        <div class="payment-confirmation-data-block-row-label">סכום לתשלום:</div>
                        <div class="payment-confirmation-data-block-row-data">{{ price }} ש"ח</div>
                    </div>

                </div>

            </div>

            <div class="payment-confirmation-data-bottom-block">

                <div class="payment-confirmation-data-bottom-block-text"><span class="red-text">לאחר אימות הפרטים לא ניתן לבטל את העסקה!</span></div>

                <div class="payment-confirmation-data-bottom-block-button">
                    <a class="link payment-button" @click="handleSupplierPaymentConfirmationSubmit">אישור פרטים וביצוע רכישה</a>
                </div>

                <div class="payment-confirmation-data-bottom-block-text"><span class="">בסיום התשלום צריך לספק ללקוח קבלה בלבד!</span></div>

            </div>

        </section>

    </main>
</template>

<script>
    import {mapState, mapActions} from 'vuex'
    import appConfig from '../../../appConfig'

    export default {
        name: 'SupplierPaymentConfirmation187',
        props: {
            msg: String
        },
        data() {
            return {
                currentDate: new Date()
            }
        },
        mounted() {
        },
        computed: {
            ...mapState({
                current_payment_data: state => state.supplier_payment.current_payment_data,
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                user_details: state => state.account.user_details && state.account.user_details.hasOwnProperty('details') ? state.account.user_details.details : null
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            paymentData() {
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                return paymentData
            },
            customerId() {
                return this.paymentData && this.paymentData.hasOwnProperty('customerId') ? this.paymentData.customerId : ''
            },
            billNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('billNumber') ? this.paymentData.billNumber : ''
            },
            sumToPay() {
                return this.paymentData && this.paymentData.hasOwnProperty('sumToPay') ? this.paymentData.sumToPay : ''
            },
            finalCommission() {
                return this.paymentData && this.paymentData.hasOwnProperty('finalCommission') ? this.paymentData.finalCommission : ''
            },
            price() {
                return this.paymentData && this.paymentData.hasOwnProperty('price') ? this.paymentData.price : ''
            },
            itemId() {
                return this.paymentData && this.paymentData.hasOwnProperty('itemId') ? this.paymentData.itemId : ''
            },
            phoneNumber() {
                return this.paymentData && this.paymentData.hasOwnProperty('phoneNumber') ? this.paymentData.phoneNumber : ''
            },
            businessName() {
                return this.user_details && this.user_details.businessName ? this.user_details.businessName : ''
            },
            currentDateString() {
                let currentDateString = this.currentDate.getDate().toString().padStart(2, '0') + '/' + (this.currentDate.getMonth()+1).toString().padStart(2, '0') + '/' + this.currentDate.getFullYear()

                return currentDateString
            },
            currentTimeString() {
                let currentTimeString = this.currentDate.getHours().toString().padStart(2, '0') + ":" + this.currentDate.getMinutes().toString().padStart(2, '0') + ":" + this.currentDate.getSeconds().toString().padStart(2, '0')

                return currentTimeString
            }
        },
        created() {
        },
        methods: {
            ...mapActions('supplier_payment', {
                supplierPayment: 'supplierPayment'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            handleSupplierPaymentConfirmationSubmit() {
                const supplierId = this.supplierId
                const itemId = this.itemId
                const customerId = this.customerId
                const billNumber = this.billNumber
                const price = this.price
                const phoneNumber = this.phoneNumber

                if (supplierId != '' && itemId != '' && customerId != '' && billNumber != ''
                    && phoneNumber != '' && price != '') {
                    this.supplierPayment(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                itemId: itemId,
                                customerId: customerId,
                                billNumber: billNumber,
                                phoneNumber: phoneNumber,
                                price: price
                            },
                        })
                        .then(
                            result_data => {
                            },
                            error => {
                            }
                        );
                }
            }
        },
        updated: function () {
        },
    }
</script>

<style>
</style>