<template>
  <main class="supplier-payment supplier-payment-193 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.MUNICIPAL_TXT1") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/monicipal-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt10">
        </div>
      </div>
    </section>

    <section class="payment-form-section m-t-40">
      <form class="payment-form payment-form-2-columns payment-193">
        <div class="payment-form-column form-div">
          <div class="payment_field_wrapper width-limit">
            <label class="label payment_field_label" for="">
              {{ $t("SUPPLIER_PAYMENT.PAYER_CUSTOMER_ID") }}
            </label>
            <input
              class="input"
              type="text"
            />
          </div>
          <div class="payment_field_wrapper width-limit">
            <label class="label payment_field_label" for="">
              {{ $t("SUPPLIER_PAYMENT.CLEARANCE_VOUCHER_APPENDIX_NUMBER") }}
            </label>
            <input
              class="input"
              type="text"
            />
          </div>
          <div class="payment_field_wrapper width-limit">
            <label class="label payment_field_label" for="">
              {{ $t("TOURISM_PROFIT_PERCENTAGES.MOBILE_PHONE") }}
            </label>
            <input
              id="contractNumber"
              class="input"
              type="tel"
            />
          </div>

          <a class="link payment-button width-limit">{{ $t("SUPPLIER_PAYMENT.CHECK") }}</a>
        </div>

        <div class="payment-form-column">
          <div class="form-description-img-wrapper">
            <img
              class="form-description-img"
              src="/assets/img/bill/m-bill.png"
            />
          </div>
        </div>
      </form>
    </section>
  </main>
</template>

<script>

export default {
  name: "SupplierPaymentConfirmation193"
};
</script>

<style lang="scss" scoped>
.supplier-payment-193 {
  .payment-caption-container {
    align-items: center;
    .payment-caption-wrapper {
      margin-top: 0;
    }
    .supplier-logo {
      max-width: 90px;
      min-width: unset;
    }
    .payment-product-name {
      font-size: 30px;
    }
  }
  .form-description-img-wrapper {
    text-align: center;
    .form-description-img {
      width: 80%;
    }
  }
  .form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    .width-limit {
      width: 80%;
    }
    .modal-discount__addition {
      margin-right: 30px;
      &::before {
        left: unset;
        right: -30px;
      }
      
    }
    .payment-button {
      margin-top: 35px;
    }
    .payment-form-section-bottom-block {
      margin-top: 20px;
      .payment-form-section-bottom-block-text {
        font-size: 28px;
      }
    }
  }
  
}
</style>