<template>
  <main class="supplier-payment supplier-payment-999 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name pt10">
          <span>{{ $t("SUPPLIER_PAYMENT.LOAD_MULTILINE_CARD") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img class="supplier-logo" src="/assets/img/card.png" />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-end pt10">
        <router-link
          :to="{
            name: 'SupplierPaymentResult',
            params: {supplier_id: '999' }}" 
            tag="a"
            exact
        >
          <a @click="showContracts" class="primary-btn bg-2 cancel-btn pointer">
            {{ $t("SUPPLIER_PAYMENT.CANCELATION") }}
          </a>
          </router-link>
        </div>
      </div>
    </section>
    <section class="product__top m-t-40">
      <div class="card gift-product__card">
        <div class="original-card-div">
          <div class="m-b-20">
            <h3
              class="title title__h1 card__title gift-product__card-title_amazon fs25"
            >
              <span class="card__span"
                >{{choosen_contract.display.detail[0]}} - {{choosen_contract.display.detail[3]}}</span
              >
            </h3>
          </div>

          <div class="ravkav-card bg-white">
            <div class="card-title">
              {{ $t("SUPPLIER_PAYMENT.BUS_CARD") }}
            </div>
            <div class="card-content align-items-baseline">
              <div class="m-b-15">
                <b>{{choosen_contract.display.detail[0]}} - </b>
                {{choosen_contract.display.detail[1]}}
              </div>
              <div class="m-b-15">
                <b>{{choosen_contract.display.detail[3]}}</b>
              </div>
              <div class="m-b-15">
                <b>{{choosen_contract.display.detail[4].slice(0,5)}}</b>
                <b class="color-red2 fs23">{{choosen_contract.display.detail[4].slice(5)}}</b>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="confirm-phone product__confirm-phone gift-product__confirm-phone_amazon"
      >
        <div class="confirm-phone__container">
          <div class="confirm-phone__header">
            <div class="logo confirm-phone__logo">
              <a class="link d-flex" href="index.html">
                <img class="img" :src="currentLogo" alt="" />
              </a>
            </div>
            <button class="button confirm-phone__close">
              <img src="assets/img/close.svg" alt="" />
            </button>
          </div>

          <div class="confirm-phone__wrapper">
            <div class="confirm-phone__title">
              <span class="confirm-phone__price fs18"
                >{{ $t("COMMON.PRICE") }}:
              </span>
              <b class="confirm-phone__price-value color-red2 fs28">{{choosen_contract.display.detail[4].slice(5)}}</b>
            </div>

            <form class="confirm-phone__form" action="">
              <div class="confirm-phone__enter">
                <label class="confirm-phone__label" for="phone_number"> {{ $t("SUPPLIER_PAYMENT.PHONE_NUMBER") }}</label>
                <input id="phone_number" v-model="payment.phone_number" class="input confirm-phone__input" type="tel" />
              </div>
            </form>
            <a
              id="gift-product__button"
              class="link confirm-phone__button"
              @click="approvePaymentHandler"
              >{{ $t("SUPPLIER_PAYMENT.ORDER")}}</a
            >
          </div>
        </div>
      </div>
    </section>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {
    Loading
  },
  name: "SupplierPaymentConfirmation999",
  props: {
    msg: String,
  },
  data: () => {
    return {
        payment: {
            phone_number: '',
            confirmed: false
        },
        paymentConfirmationModal: {
            active: false,
        },
        paymentVerificationCodeModal: {
            active: false,
            verification_code: null,
            transaction_id: null
        },
    }
  },
  computed: {
    ...mapState({
        choosen_contract: (state) => state.chargeRavkav_module.choosen_contract,
        uid: (state) => state.chargeRavkav_module.uid,
        contract_approved: (state) => state.chargeRavkav_module.contract_number,
        loading: (state) => state.chargeRavkav_module.loading
    }),
     contractNumber () {
      return this.$route.params.contract_number
    },
    supplierId () {
      return this.$route.params.supplier_id
    },
    productId() {
      return this.contractNumber
    }
  },
  created() {
    this.getContractDetails({contract_number: this.contractNumber})
  },
  methods: {
   ...mapActions('chargeRavkav_module', {
        getContractDetails:'getContractDetails',
        ravkavPaymentRequest:'ravkavPaymentRequest',
        showResult: 'showResult'
      }),
      showContracts() {
        this.showResult()
      },
      approvePaymentHandler() {
        this.payment.confirmed = true;
        this.handlePaymentSubmit();
        //this.closePaymentConfirmationModal();
      },
      handlePaymentSubmit() {
        let obj = this;

        if (this.payment.confirmed) {
            const phone_number = obj.payment.phone_number
            const supplier_id = obj.supplierId
            const product_id = obj.productId
            if (supplier_id && product_id && phone_number) {
                this.ravkavPaymentRequest({
                    supplier_id: supplier_id,
                    payment_data: {
                        itemId: 2076,
                        phoneNumber: phone_number
                    }
                })
            }
        }
      },
      makeTransaction() {
        this.createTransaction()
      }
  }
};
</script>

<style lang="scss" scoped>
.supplier-payment-999 {
  .card::before {
    content: none;
  }
  .supplier-logo {
    width: 120px;
  }
  .payment-product-name {
    font-size: 30px;
  }
  .cancel-btn {
    width: 160px;
  }
  .ravkav-card {
    padding: 30px 40px;
    .card-title {
      font-size: 45px;
      color: #5c419b;
      font-weight: 700;
      margin-bottom: 25px;
    }
    .card-content {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
    }
  }
  .original-card-div {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid #b2bbc2;
  }
  
  @media screen and (max-width: 767px) {
    .card-content {
      flex-wrap: wrap;
    }
  }
}
</style>